<template>
  <div class="app app-focused">
    <header class="app-header mt-2 flex flex-row items-center justify-between">
      <NuxtLink :to="localizePath('/')">
        <Icon name="material-symbols:arrow-back" />
        {{ t('Back to app') }}
      </NuxtLink>

      <div
        class="tooltip tooltip-left hover:tooltip-open"
        :data-tip="t('Close')"
      >
        <button class="btn-outline btn-square btn" @click="close()">
          <span class="label sr-only">{{ t('Close') }}</span>
          <Icon class="text-5xl" name="material-symbols:close" />
        </button>
      </div>
    </header>
    <main class="app-main">
      <div class="app-page container mx-auto my-8">
        <slot />
      </div>
    </main>
    <footer class="container">
      <p
        class="inline-flex flex-row gap-2 py-2 font-montserrat italic text-xs text-gray-500"
      >
        <span>BeUnity App |</span>
        <span>Version: {{ build.version }}</span>
        <span>Build: {{ build.id }}</span>
      </p>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import { isEqual } from 'ufo'
const route = useRoute()
const { t } = useI18n()
const localizePath = useLocalePath()

const { build } = useRuntimeConfig().public

type PreviousPathType = { path: string; query: Record<string, string> }
const previousPath = useState<PreviousPathType>('previous-path')

const close = () => {
  const prev = unref(previousPath)
  if (isEqual(route.path, prev.path)) {
    prev.path = '/'
  }

  const path = localizePath(prev.path)

  console.log(`Closing focused panel. returning to "${path}"`)

  navigateTo({
    path,
    query: prev.query,
  })
}
</script>
